import type { PaginationByPage } from '@nuxtjs/strapi'
import type { CompanyFileCategories } from '@api-types/components/CompanyFileCategories'
import type { StrapiMultipleResponse } from '../../../../../admin/import/types'
import type { Company, Issue } from '~/api/types'

export type GetCompaniesListServiceInput = {
  pagination: PaginationByPage
  search?: string
}

export enum ShortCompanyKeys {
  name = 'name',
  companyId = 'companyId',
  slug = 'slug',
}

export const ShortCompanyIssueKeys = {
  jamesOrderFormURL: 'jamesOrderFormURL',
  isAvailable: 'isAvailable',
}

export const ShortCompaniesPopulate = {
  // Issues
  issues: {
    populate: ['interestRate'],
  },
}

export type ShortCompanyIssue = Pick<Issue, keyof typeof ShortCompanyIssueKeys>

export type ShortCompany = Pick<Company, ShortCompanyKeys> & {
  issues: StrapiMultipleResponse<ShortCompanyIssue>
}

export const CompanyFileCategoriesKeys: { [key in keyof Omit<CompanyFileCategories, 'id'>]: string } = {
  baseProspectus: 'baseProspectus',
  cashFlowStatement: 'cashFlowStatement',
  financialStatement: 'financialStatement',
  balanceSheet: 'balanceSheet',
  incomeStatement: 'incomeStatement',
  annualReport: 'annualReport',
  auditReport: 'auditReport',
  otherDocuments: 'otherDocuments',
}
