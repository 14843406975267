import type { IssueFileCategories } from '@api-types/components/IssueFileCategories'
import type { Issue } from '~/api/types'
import type { ShortIssueWithId } from '~/api/modules/issues/services/getIssuesList/getIssuesList.types'

export type getIssuesDetailsInput = {
  withMoreIssuerIssues?: boolean
  withShowMore?: boolean
}

export type IssueDetails = Issue & {
  id?: number
  moreIssuerIssues?: ShortIssueWithId[]
  showMoreIssues?: ShortIssueWithId[]
}

export const IssueFileCategoriesKeys: { [key in keyof Omit<IssueFileCategories, 'id'>]: string } = {
  issueTermSheet: 'issueTermSheet',
  collateralInstruments: 'collateralInstruments',
  prospectusAmendment: 'prospectusAmendment',
  otherDocuments: 'otherDocuments',
  amendmentToTermSheet: 'amendmentToTermSheet',
}
