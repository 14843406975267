import type { Strapi4ResponseMany } from '@nuxtjs/strapi'
import { apiRequest } from '~/api/helpers/apiRequest'
import type { BusinessCategory } from '~/api/types'

export function getBusinessCategoriesList() {
  return apiRequest <Strapi4ResponseMany<BusinessCategory>>('/business-categories', {
    pagination: { start: 0, limit: 100 },
    populate: ['icon', 'companies'],
  })
}
