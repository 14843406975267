import type { Strapi4Response } from '@nuxtjs/strapi'
import type { Homepage } from '~/api/types'
import { simplifyStrapiResponse } from '~/api/helpers/functions'
import { SEO_FIELDS } from '~/constants/seo'
import { apiRequest } from '~/api/helpers/apiRequest'

export async function getHomepage(): Promise<Homepage | null> {
  const response = await apiRequest<Strapi4Response<Homepage>>('/homepage', {
    populate: [
      'benefits',
      'benefits.icon',
      'banner',
      'banner.image',
      'banner.imageDesktop',
      'banner.imageMobile',
      'heroBanner',
      'heroBanner.defaultText',
      'heroBanner.conditionalText',
      'heroBanner.disclaimer',
      'heroBanner.filterTitle',
      ...SEO_FIELDS,
    ],
  })

  return response ? simplifyStrapiResponse<Homepage>(response) : null
}
