import type { Strapi4Response } from '@nuxtjs/strapi'
import { apiRequest } from '~/api/helpers/apiRequest'
import { simplifyStrapiResponse } from '~/api/helpers/functions'
import type { Footer } from '~/api/types'

export async function getFooterList(): Promise<Footer | null> {
  const response = await apiRequest<Strapi4Response<Footer>>(
    '/footer',
    {
      populate: ['sections', 'sections.links'],
    },
  )

  return response ? simplifyStrapiResponse<Footer>(response) : null
}
