import type { Strapi4Response, Strapi4ResponseSingle } from '@nuxtjs/strapi'
import { pick } from 'lodash-es'
import { simplifyStrapiCollectionResponse, simplifyStrapiResponse } from '~/api/helpers/functions'
import type { Issue } from '~/api/types'
import type {
  IssueDetails,
} from '~/api/modules/issues/services/getIssueDetails/getIssueDetails.types'
import {
  IssueFileCategoriesKeys,
} from '~/api/modules/issues/services/getIssueDetails/getIssueDetails.types'
import { getIssuesList } from '~/api/modules/issues/services/getIssuesList/fuctions/getIssuesList'
import { apiRequest } from '~/api/helpers/apiRequest'
import { populateCompanyDetails } from '~/api/modules/companies/services/getCompanyDetails.service'

export async function getIssueDetails(slug: string, companyId?: number): Promise<IssueDetails> {
  // Company id can be passed from query string
  if (companyId) {
    const [showMoreIssues, issue] = await Promise.all([
      getMoreIssues(companyId, slug),
      fetchDetails(slug),
    ])

    return {
      ...simplifyStrapiResponse<Issue>(issue as unknown as Strapi4Response<Issue>),
      showMoreIssues: showMoreIssues?.data || [],
    }
  }

  // When company id is not passed, we need to fetch it from issue
  const response = await fetchDetails(slug) as unknown as Strapi4Response<Issue>
  const issue: IssueDetails | undefined = simplifyStrapiResponse<Issue>(response) || undefined
  const showMoreIssues = issue.company?.data?.id ? (await getMoreIssues(issue.company?.data?.id, slug))?.data || [] : []

  return { ...issue, showMoreIssues }
}

async function getMoreIssues(companyExcludeId: number, slug: string) {
  return await getIssuesList({
    filters: {
      notEqualSlug: slug,
      onlyOur: true,
      isNotArchived: true,
      excludedCompanyIds: [companyExcludeId],
    },
    pagination: { start: 0, limit: 4 },
  })
}

function buildFileCategoriesPopulate(keys: string[]) {
  return keys.reduce((acc, key) => {
    acc[key] = {
      populate: {
        files: {
          populate: { file: { fields: ['name', 'url'] } },
        },
      },
    }
    return acc
  }, {} as Record<string, any>)
}

async function fetchDetails(slug: string) {
  const response = await apiRequest<Strapi4ResponseSingle<Issue>>('/issues/0', { // id === 0 because we don't want to search by id
    customQuery: { slug },
    populate: {
      mfcrScoreImage: '*',
      termSheet: '*',
      interestRate: '*',
      returns: '*',
      blogs: {
        populate: {
          poster: '*',
          author: '*',
        },
      },
      heroSection: {
        populate: ['youtubeUrl', 'image', 'video'],
      },

      seo: {
        populate: {
          metaSocial: {
            populate: ['image'],
          },
        },
      },

      collaterals: {
        populate: {
          textVariables: '*',
          files: {
            populate: {
              file: { fields: ['name', 'url'] },
            },
          },
        },
      },

      scoreData: {
        populate: ['image'],
      },

      fileCategories: {
        populate: buildFileCategoriesPopulate(Object.keys(IssueFileCategoriesKeys)),
      },

      company: {
        where: {
          publishedAt: { $notNull: true },
        },
        populate: populateCompanyDetails(),
      },
    },
  })

  const company = response?.data?.attributes?.company
  const issues = company?.data?.attributes?.issues as Strapi4Response<Issue> | undefined

  return {
    data: {
      id: response?.data?.id,
      attributes: {
        ...response?.data?.attributes,
        company: {
          data: {
            id: company?.data?.id,
            attributes: {
              ...company?.data?.attributes || {},
              issues: {
                data: issues?.data
                  ? simplifyStrapiCollectionResponse<Issue>(issues)
                    .map(issue => ({
                      ...issue,
                      company: { data: { id: company?.data?.id, attributes: pick(company?.data?.attributes, ['name']) } },
                    }))
                    .filter(issue => issue.slug !== slug)
                  : undefined,
              },
            },
          },
        },
      },
    },
    meta: response?.meta,
  }
}
