import type { Strapi4RequestParams, Strapi4Response } from '@nuxtjs/strapi'
import type { ShortIssue } from '../getIssuesList.types'
import { prepareFilter } from '../helpers/functions'
import { apiRequest } from '~/api/helpers/apiRequest'
import type { IssuesFiltersState } from '~/stores/issues/useIssuesStore'
import type { ApiResponseMeta } from '~~/api/types'
import type { IssuesStoreTotalCount } from '~/stores/issues/types'

type GetIssuesTotalCountPayload = {
  filters?: IssuesFiltersState
  search?: string
  areArchivedIncluded?: boolean
}

export async function getIssuesTotalCount({ filters, search, areArchivedIncluded }: GetIssuesTotalCountPayload = {}): Promise<IssuesStoreTotalCount> {
  const params: Strapi4RequestParams = {
    fields: ['id'],
  }

  if (filters) {
    params.filters = prepareFilter({
      ...filters,
      isNotArchived: !areArchivedIncluded ? true : undefined,
    })
  }

  if (search) {
    params.filters = {
      $and: [
        params.filters,
        {
          $or: [
            { company: { name: { $containsi: search } } },
            { name: { $containsi: search } },
            { isin: { $containsi: search } },
          ],
        },
      ],
    }
  }

  try {
    const response = await apiRequest<Strapi4Response<ShortIssue[]>>(
      '/issues',
      { ...params, pagination: { start: 0, limit: 1 } },
    )

    return response ? (response.meta as ApiResponseMeta).pagination?.total || 0 : 0
  }
  catch (e) {
    console.error(e)
  }
}
