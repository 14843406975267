import type { Strapi4ResponseSingle } from '@nuxtjs/strapi'
import { buildFileCategoriesPopulate, simplifyStrapiCollectionResponse } from '~/api/helpers/functions'
import { apiRequest } from '~/api/helpers/apiRequest'
import type { Company } from '~/api/types'
import {
  CompanyFileCategoriesKeys,
} from '~/api/modules/companies/types/companies.types'

export async function getCompanyDetails(slug: string): Promise<Company> {
  const data = await fetchDetails(slug)
  return data as Company
}

export function populateCompanyDetails() {
  return {
    lustration: {
      populate: {
        insolvency: '*',
        liquidation: '*',
        unreliableVat: '*',
      },
    },
    identification: '*',
    blogs: {
      where: {
        publishedAt: { $notNull: true },
      },
      populate: {
        author: '*',
        poster: '*',
      },
    },
    contact: '*',
    financialResults: {
      populate: ['statements'],
    },
    statutory: '*',
    ownerships: '*',
    extendedDetail: '*',
    fileCategories: {
      populate: buildFileCategoriesPopulate(Object.keys(CompanyFileCategoriesKeys)),
    },
    issues: {
      where: {
        publishedAt: { $notNull: true },
      },
      populate: {
        interestRate: '*',
      },
    },
    heroSection: {
      populate: {
        image: '*',
        video: '*',
      },
    },
    news: '*',
  }
}

async function fetchDetails(slug: string): Promise<Company> {
  const response = await apiRequest<Strapi4ResponseSingle<Company>>('/companies', {
    filters: { slug: { $eq: slug } },
    populate: populateCompanyDetails(),
  })
  const company = simplifyStrapiCollectionResponse<Company>(response)[0]
  return company
}
