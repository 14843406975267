import type { Strapi4RequestParams, Strapi4ResponseMany } from '@nuxtjs/strapi'
import { apiRequest } from '~/api/helpers/apiRequest'
import type { GetCompaniesListServiceInput, ShortCompany } from '~/api/modules/companies/types/companies.types'
import { ShortCompaniesPopulate, ShortCompanyKeys } from '~/api/modules/companies/types/companies.types'

export async function getCompaniesList({
  pagination,
  search = '',
}: GetCompaniesListServiceInput): Promise<Strapi4ResponseMany<ShortCompany>> {
  const params: Strapi4RequestParams = {
    fields: Object.values(ShortCompanyKeys),
    sort: ['name:asc'],
    populate: ShortCompaniesPopulate,
    filters: {
      issues: {
        publishedAt: { $notNull: true },
      },
    },
  }

  if (pagination) {
    params.pagination = pagination
  }

  if (search) {
    const searchFilter = {
      issues: {
        publishedAt: { $notNull: true },
      },
      $or: [
        { name: { $containsi: search } },
        { companyId: { $eqi: search } },
      ],
    }

    if (params.filters?.$and) {
      (params.filters.$and as Record<string, unknown>[]).push(searchFilter)
    }
    else if (params.filters?.$or) {
      (params.filters.$or as Record<string, unknown>[]).push(searchFilter)
    }
    else if (Array.isArray(params.filters)) {
      params.filters.push(searchFilter)
    }
    else {
      params.filters = searchFilter
    }
  }

  return await apiRequest<Strapi4ResponseMany<ShortCompany>>(
    '/companies/page',
    params,
  )
}
