import { businessCategoriesModule } from './modules/businessCategories/businessCategories.module'
import { issuesModule } from '~/api/modules/issues/issues.module'
import { apiRequest } from '~/api/helpers/apiRequest'
import { apiPages } from '~/api/pages'
import { companiesModule } from '~/api/modules/companies/companies.module'
import { footerModule } from '~/api/modules/footer/footer.module'

export class Api {
  static request = apiRequest

  public issuesModule = issuesModule
  public companiesModule = companiesModule
  public footerModule = footerModule
  public bussinessCategoriesModule = businessCategoriesModule

  public pages = apiPages
}

export const api = new Api()
